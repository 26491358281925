:root {
    --grid: 10px;
    --center-width: 300px;
    --border-radius: 5px;
}

body {
    background: #001628;
    color: #d4deec;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@keyframes flash {
    0% {
        background-color: #001628;
    }
    100.0% {
        background-color: #860000;
    }
}

body[spell="silence"] {
    animation-name: flash;
    animation-duration: 500ms;
    animation-iteration-count: 6;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    background-color: #860000;
}

a {
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

hr {
    width: var(--center-width);
    border: none;
    border-top: 1px solid #17385f;
    margin-top: calc(var(--grid) * 2);
}
